
import { Options, Vue } from "vue-class-component";
import { userAPI } from "@/utils/apiCalls";

@Options({
  data() {
    return {
      soundEnabled: false,
      musicEnabled: false,
      savedSetting: "",
    };
  },
  methods: {
    //Toggle sound on and off
    toggleSound(): void {
      this.soundEnabled = !this.soundEnabled;
    },
    //Toggle music on and off
    toggleMusic(): void {
      this.musicEnabled = !this.musicEnabled;
    },
    //Update store on music or sound update
    updateStore(): void {
      this.$store.commit("setSoundSettings", this.soundEnabled);
      this.$store.commit("setMusicSettings", this.musicEnabled);
    },
    //Update local storage on music or sound update
    updateLocalStorage(): void {
      localStorage.setItem(
        "userInfo",
        JSON.stringify(this.$store.getters.getUserInfo)
      );
    },
    //Update database on music or sound update
    updateDatabase(): void {
      let success: Promise<number | null>;
      success = userAPI.setUserPreferences(this.$store.getters.getPreferences);

      if (success !== null) {
        this.savedSetting = "succeeded";
      } else {
        this.savedSetting = "failed";
      }
      //Timer for the action success text
      setTimeout(() => {
        this.savedSetting = "";
      }, 5000);
    },
  },
  beforeMount() {
    //Set music and sound value
    this.soundEnabled = this.$store.getters.getPreferences.soundEnabled;
    this.musicEnabled = this.$store.getters.getPreferences.musicEnabled;
  },
})
export default class GameSettingsBlock extends Vue {}
