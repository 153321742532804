
import { Options, Vue } from "vue-class-component";
import { userAPI } from "@/utils/apiCalls";

@Options({
  data() {
    return {
      languages: ["en", "nl"],
      selectedWebsiteLanguage: "",
      selectedIDELanguage: "",
      programmingStyles: ["schematically", "typed"],
      selectedProgrammingStyle: "",
      savedSetting: "",
    };
  },
  methods: {
    /**
     * Select website language
     * @param language Language to set
     */
    selectWebsiteLanguage(language: string): void {
      this.selectedWebsiteLanguage = language;
    },
    /**
     * Select programming language
     * @param language Language to set
     */
    selectIDELanguage(language: string): void {
      this.selectedIDELanguage = language;
    },
    /**
     * Select programming style
     * @param programmingStyle Style to set
     */
    selectProgrammingStyle(programmingStyle: string): void {
      this.selectedProgrammingStyle = programmingStyle;
    },
    //Update store with new settings
    updateStore(): void {
      this.$store.commit("setWebsiteLanguage", this.selectedWebsiteLanguage);
      this.$store.commit("setIDELanguage", this.selectedIDELanguage);
      this.$store.commit("setProgrammingStyle", this.selectedProgrammingStyle);
    },
    //Update local storage with new settings
    updateLocalStorage(): void {
      localStorage.setItem(
        "userInfo",
        JSON.stringify(this.$store.getters.getUserInfo)
      );
    },
    //Update database with new settings
    updateDatabase(): void {
      let success: Promise<number | null>;
      success = userAPI.setUserPreferences(this.$store.getters.getPreferences);

      if (success !== null) {
        this.savedSetting = "succeeded";
      } else {
        this.savedSetting = "failed";
      }
      //Timer for the action success text
      setTimeout(() => {
        this.savedSetting = "";
      }, 5000);
    },
  },
  mounted() {
    //Set settings from store
    this.selectedWebsiteLanguage = this.$store.getters.getPreferences.siteLang;
    this.selectedIDELanguage = this.$store.getters.getPreferences.ideLang;
    this.selectedProgrammingStyle =
      this.$store.getters.getPreferences.programmingStyle;
  },
})
export default class LanguageSettingsBlock extends Vue {}
