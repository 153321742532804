
import { Options, Vue } from "vue-class-component";
import GameSettingsBlock from "@/components/settings/GameSettingsBlock.vue";
import LanguageSettingsBlock from "@/components/settings/LanguageSettingsBlock.vue";
import QuestionButton from "@/components/QuestionButton.vue";

@Options({
  data() {
    return {
      wikiSubjects: [],
    };
  },

  mounted() {
    this.updateQuestionButton();
  },

  methods: {
    updateQuestionButton() {
      this.wikiSubjects.push(
        { wikiButton: { name: this.$t("wiki.setting"), item: "settingPage" } },
        { wikiButton: { name: this.$t("wiki.lang"), item: "langPage" } },
        { wikiButton: { name: this.$t("wiki.progway"), item: "progwayPage" } }
      );
    },
  },

  components: {
    GameSettingsBlock,
    LanguageSettingsBlock,
    QuestionButton,
  },
})
export default class Settings extends Vue {}
